import { useEffect } from 'react';
import { setRem } from '@/libs';
import _ from 'lodash';

export const useSetHtmlFontSize: (props?: {
  baseSize?: number;
}) => void = props => {
  const resizeHandle = _.debounce(() => {
    setRem({ baseSize: props?.baseSize });
    const height = window.innerHeight;
    document.documentElement.style.setProperty('height', `${height / 100}rem`);
  }, 200);

  useEffect(() => {
    setRem({ baseSize: props?.baseSize });
    const height = window.innerHeight;
    document.documentElement.style.setProperty('height', `${height / 100}rem`);
    window.addEventListener('resize', resizeHandle);
    return () => {
      window.removeEventListener('resize', resizeHandle);
    };
  }, []);
};
