export const setRem = ({ baseSize }: { baseSize?: number }) => {
  const BaseSize = baseSize;
  const maxClientWidth = 640;
  const clientWidth =
    document.documentElement.clientWidth > maxClientWidth
      ? maxClientWidth
      : document.documentElement.clientWidth;
  // 当前页面宽度相对于 750 宽的缩放比例
  const scale = clientWidth / 750;
  // 设置页面根节点字体大小, 字体大小最小为12
  let fontSize =
    (BaseSize || 100) * Math.min(scale, 2) > 12
      ? (BaseSize || 100) * Math.min(scale, 2)
      : 12;
  document.documentElement.style.fontSize = fontSize + 'px';
};
