import * as Sentry from '@sentry/nextjs';

const SENTRY_DSN = process.env.SENTRY_DSN;
const release = process.env.SENTRY_RELEASE;

Sentry.init({
  dsn:
    SENTRY_DSN ||
    'https://b88d7589a4b17a44df13ab0e1bdb594b@sentry.manjuu.com/5',
  tracesSampleRate: 1.0,
  environment: process.env.APP_ENV,
  release,
});

// export {};
